<template>
  <el-row>
    <el-col :lg="6" :md="12" :sm="24" :xl="6" :xs="24">
      <el-card class="box-card" style="margin-right: 10px; height: 600px">
        <el-scrollbar >
          <div v-for="item in list" :key="item.id">
            <el-link style="margin-bottom: 5px" :underline="false" :type="currentId == item.id?'primary':''" @click="getTemplate(item.id)">{{item.category}} - {{item.scene}}</el-link>
          </div>
        </el-scrollbar>
      </el-card>
    </el-col>
    <el-col :lg="18" :md="12" :sm="24" :xl="18" :xs="24">
      <el-card class="el-card-define">
        <el-form
            label-width="120px"
        >
          <el-form-item label="插入">
            <el-select
                size="small"
                placeholder="请选择"
                @change="selectHandle"
                v-model="selectValue"
            >
              <el-option
                  v-for="item in form.var_list"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="主题">
            <el-input v-model="form.subject"/>
          </el-form-item>
          <el-form-item label="是否启用">
            <el-switch v-model="form.status" :active-value="1" :inactive-value="0"/>
          </el-form-item>
          <el-form-item>
            <div>
              <tinymce v-model="form.template" :height="600" />
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" :disabled="!form.id" style="margin-top: 20px" @click="submitForm">
              保存
            </el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import {apiGetEmailTemplate, apiGetEmailTemplateList,apiUpdateEmailTemplate} from "@/request/api"
export default {
  name: 'index',
  data() {
    return {
      list: [],
      form:{},
      currentId:null,
      selectValue:''
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData(){
      apiGetEmailTemplateList().then(res => {
        if(res.code == 200){
          this.list =res.data.list
        }else{
          this.$message.error(res.message)
        }
      })
    },
    getTemplate(id){
      this.currentId = id
      apiGetEmailTemplate({id}).then(res => {
        if(res.code == 200){
          this.form =res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    selectHandle(e){
      let label = '{'+e+'}'
      let tempEle= document.createElement('input') // 创建一个输入框，先把要粘贴的值放进去
      tempEle.value = label // 赋值
      document.body.appendChild(tempEle)
      tempEle.select() // 对输入框中的内容进行选中
      document.execCommand('Copy') // 浏览器复制方法
      tempEle.className = 'tempEle' // 定义新加元素的样式
      tempEle.style.display = 'none' // 新加元素没用了，做隐藏
      this.$message({ message: '复制成功', type: 'success' })
    },
    submitForm(){
      apiUpdateEmailTemplate(this.form).then(res => {
        if(res.code == 200){
          this.$message.success(res.message)
        }else{
          this.$message.error(res.message)
        }
      })
    }
  },
}
</script>
<style scoped>
</style>
